/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.core();

@import 'variables';

$md-mcgpalette1: (
  50 : #eaf5ea,
  100 : #c9e7cb,
  200 : #a6d7a8,
  300 : #82c785,
  400 : #67bb6a,
  500 : #4caf50,
  600 : #45a849,
  700 : #3c9f40,
  800 : #339637,
  900 : #3f51b5,
  A100 : #c5ffc7,
  A200 : #92ff95,
  A400 : #5fff64,
  A700 : #46ff4b,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$my-primary: mat.define-palette($md-mcgpalette1, 900);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

@include mat.all-component-themes($my-theme);
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Montserrat+Subrayada&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

html,
body {
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-dialog-container {
  border-radius: 20px !important;
}


.adaptive-modal {
  .mat-mdc-dialog-container {
    padding: 0 !important;
  }

  .mdc-dialog__surface {
    border-radius: 10px !important;
    overflow: hidden !important;
  }

  .mat-mdc-dialog-content {
    padding: 0 !important;
    margin: 0 !important;
    display: block !important;
  }
}

.cdk-overlay-pane {
  max-height: 95vh !important;
}